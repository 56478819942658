import { environment } from "./environment.base";
export { environment };

environment.name = 'qa';
environment.parentUrl =  'https://qa.strayos.com';
environment.baseUrl = 'https://qadashboards.strayos.com';
environment.apiUrl = 'https://devx.strayos.com:3012';
// environment.apiUrl = 'https://devx.strayos.com:3020';
environment.powerbiEmbedTokenUrl='https://powerbi-embed-token.azurewebsites.net/api/GetEmbedToken';
environment.pbWorkspaceId='2410fb75-fdae-40a9-a59a-81c170ef8260';
environment.drillAndBlastDashboardReportId='60533b24-47d0-4d77-ae96-c880c92e4031';
environment.fielderDashboardReportId='8d0e7dc2-7b58-4bc7-85e0-c23fac5d619d';
environment.fragmentationDashboardReportId='08020978-1957-4e6f-b134-7d37910f8669';
