import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { Embed, IReportEmbedConfiguration, models, service } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';

interface ConfigResponse {
	Id: string;
	EmbedUrl: string;
	EmbedToken: {
		Token: string;
	};
}

@Component({
	selector: 'app-sites-dashboard',
	templateUrl: './sites-dashboard.component.html',
	styleUrls: ['./sites-dashboard.component.scss']
})
export class SitesDashboardComponent implements OnInit, OnDestroy {
	@ViewChild(PowerBIReportEmbedComponent) reportObj: PowerBIReportEmbedComponent;
	@Input() siteId:number;

	public reportConfig: IReportEmbedConfiguration = {
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: undefined
	};

	public eventHandlersMap = new Map ([
		['loaded', () => {
			const report = this.reportObj.getReport();

			const reportElement:HTMLElement = report.element;
			reportElement.style.visibility = 'visible';

			const overlay:HTMLElement = document.getElementById('loading-logo-overlay');
			overlay.style.display = 'none';
		  },
		],
		['rendered', () => console.log('Report has rendered')],
		['error', (event?: service.ICustomEvent<any>) => {
			if (event) {
			  console.error(event.detail);
			}
		  },
		]
	  ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;

	constructor() {

	}

	ngOnInit(): void {
		this.embedReport();
	}

	ngOnDestroy() {
		// const overlay:HTMLElement = document.getElementById('loading-logo-overlay');
		// overlay.style.display = 'block';
	}

	private async embedReport(){
		try {
			const queryParams = new URLSearchParams({workspaceId: 'efef4610-516f-40dd-8b3e-d4014bcfd490', reportId: '10e38f9f-fecb-4962-8df7-368af2e1a7a9'});

			const response = await fetch(`${environment.powerbiEmbedTokenUrl}?${queryParams}`);
			const reportConfigResponse = await response.json();

			if (reportConfigResponse?.embedUrl) {
				this.reportConfig = {
					...this.reportConfig,
					embedUrl: reportConfigResponse.embedUrl[0].embedUrl,
					accessToken: reportConfigResponse.accessToken,
				};
			}
		} catch (error: any) {
			console.error(error);
			return;
		}
	}
}
