import { BinaryHeap } from "../binary-heap";

interface Node<T>{
	item:T;
	prev:Node<T>|null;
	cost:number;
	costToFinish?:number;
	totalCost?:number;
	closed?:boolean;
}

interface PathNodeMap<T>{
	get(item:T):Node<T>|undefined;
	set(item:T, node:Node<T>):void;
	values():Iterable<Node<T>>;
}

function nodeToPath<T>(node:Node<T>|null){
	if(!node)
		return null;
	const out:T[]=[];
	while(node){
		out.push(node.item);
		node=node.prev;
	}
	out.reverse();
	return <[T,...T[]]>out;
}

type CostToMove<T>=(a:T, b:T)=>number;
type ItConnections<T>=(item:T)=>Iterable<T>;

export class PathMap<T>{
	public constructor(
		public readonly begin:T,
		private readonly itConnections:ItConnections<T>,
		private readonly costToMove:CostToMove<T>,
		private readonly map:PathNodeMap<T>=new Map<T,Node<T>>(),

	){
		this.beginNode={
			item: begin,
			prev: null,
			cost: 0,
		};
		this.map.set(begin,this.beginNode);
	}

	private readonly beginNode:Node<T>;

	public flood():void;
	public flood(end:T):[T,...T[]]|null;
	public flood(end?:T):([T,...T[]]|null)|void{
		if(end===undefined){
			//fill in the entire path map
			let working=new Set([this.beginNode]);
			while(working.size>0){
				working=this.floodIterate(working);
			}
			return;
		}
	
		//flood fill until we reach a certain item
		let working=new Set([this.beginNode]);
		while(working.size>0){
			working=this.floodIterate(working);
			const endNode=this.map.get(end);
			if(endNode)
				return nodeToPath(endNode);
		}
		return null;
	}

	public floodIterate(
		working:Set<Node<T>>,
	){
		const {map}=this;
		const next=new Set<Node<T>>();
		for(const cur of working){
			const connections=this.itConnections(cur.item);
			for(let item of connections){
				const cost=cur.cost+this.costToMove(cur.item,item);
				let node=map.get(item);
				if(!node){
					node={item,prev:cur,cost};
					map.set(item,node);
					next.add(node);
				}else if(cost<node.cost){
					node.prev=cur;
					node.cost=cost;
					//updatedCost?.(node);
					next.add(node);
				}
			}
		}
		return next;
	}

	public pathTo(
		end:T,
		heuristicCostToFinish?:(a:T,b:T)=>number,
	):T[]|null{
		let endNode=this.map.get(end);
		if(endNode){
			//we already know it
			return nodeToPath(endNode);
		}

		if(!heuristicCostToFinish){
			return this.flood(end);
		}
	
		const {map,itConnections,costToMove}=this;
		const stack=new BinaryHeap<Node<T>>(n=>n.totalCost!);
		for(const node of map.values()){
			node.closed=true;
			for(const item of itConnections(node.item)){
				if(!map.get(item)){
					node.closed=false;
					break;
				}
			}
			if(!node.closed){
				node.costToFinish=heuristicCostToFinish(node.item,end);
				node.totalCost=node.cost+node.costToFinish;
				stack.push(node);
			}
		}
	
		let itCount=0;
		let resetCount=0;
		while(stack.size>0){
			itCount+=1;
			const cur=stack.pop();
			cur.closed=true;
			const connections=itConnections(cur.item);
			for(let item of connections){
				let next=map.get(item);
				const cost=cur.cost+costToMove(cur.item,item);
				if(!next){
					next={item,prev:cur,cost};
					next.costToFinish=heuristicCostToFinish(next.item,end);
					next.totalCost=next.cost+next.costToFinish;
					map.set(item,next);
					stack.push(next);
					if(next.item===end){
						return nodeToPath(next);
					}
				}else if(!next.closed && cost<next.cost){
					next.prev=cur;
					next.cost=cost;
					next.totalCost=next.cost+next.costToFinish!;
					stack.tryBubbleUp(next);
					resetCount+=1;
				}
			}
		}
		return null;
	}	
}

type _Node<T>=Node<T>;
export namespace PathMap{
	export type Node<T>=_Node<T>;
}
