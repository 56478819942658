import './array';
import './json';
import './map';
import './number';
import './object';
import './set';
import './string';

declare global{
	interface Array<T> {
		atWrap(index:number):T;
		atClamp(index:number):T;
		concat<U>(...items:ConcatArray<U>[]):(U|T)[];
		groupsOf(groupSize:number):T[][];
		highest():T;
		highest(worth:(v:T, i:number)=>number):T;
		highestIndex():number;
		highestIndex(worth:(v:T)=>number):number;
		lowest():T;
		lowest(worth:(v:T, i:number)=>number):T;
		lowestIndex():number;
		lowestIndex(worth:(v:T)=>number):number;
		pairs(loop?:boolean):Generator<[T,T,number],void,undefined>;
		slice():this;//just keeping the type
		slice(start:0, end:1):[T,...T[]];
		slice(start:0, end:2):[T,T,...T[]];
		triplets(loop?:boolean):Generator<[T,T,T,number],void,undefined>;
		unique():T[];
	}

	interface ArrayConstructor{
		asArray<T>(arg:T):T extends any[]?(T&any[]):T[];
	}

	interface File{
		// extname(this:File):string;
	}

	interface Map<K,V>{
		array(this:Map<K,V>):V[];
		deleteIf(this:Map<K,V>, condition:(v:V,k:K)=>boolean):void;
		equals(this:Map<K,V>, that:Map<K,V>):boolean;
		filter(this:Map<K,V>, passes:(v:V,k:K)=>boolean):Map<K,V>;
		filter<U extends V>(this:Map<K,V>, passes:(v:V,k:K)=>v is U):Map<K,U>;
		getOrAdd(this:Map<K,V>, k:K, valueMaker:()=>V):V;
		keyDiff<U>(this:Map<K,V>, that:Map<K,U>):{added:Map<K,U>;deleted:Map<K,V>};
		keyDiff(this:Map<K,V>, that:Set<K>):{added:Set<K>;deleted:Map<K,V>};
		keysEqual(this:Map<K,V>, that:{keys():Iterable<any>;has(v:any):boolean;}):boolean;
		map<V2>(this:Map<K,V>, change:(v:V, k:K)=>V2):Map<K,V2>;
	}

	interface Number{
		toDecimal(this:number, decimalCount:number):string;
	}

	interface ObjectConstructor {
		fromEntries<Key extends string,Value>(iterable:Iterable<[Key,Value]>):{[key in Key]:Value}; //preserves key type
		getPropertyDescriptor(o:any, p:PropertyKey):PropertyDescriptor|undefined;
	}

	interface Set<T>{
		addAll<T>(this:Set<T>, items:Iterable<T>):void;
		array(this:Set<T>):T[];
		deleteIf(this:Set<T>, condition:(v:T)=>boolean):void;
		equals(this:Set<T>, that:Set<T>):boolean;
		every(this:Set<T>, predicate:(value:T)=>unknown):boolean;
		filter<U extends T>(this:Set<T>, passes:(v:T)=>v is U):Set<U>;
		filter(this:Set<T>, passes:(v:T)=>boolean):Set<T>;
		keyDiff(this:Set<T>, that:{keys():Iterable<any>;has(v:any):boolean;}):{added:Set<T>;deleted:Set<T>};
		keysEqual(this:Set<T>, that:{keys():Iterable<any>;has(v:any):boolean;}):boolean;
		map<T2>(this:Set<T>, change:(v:T)=>T2):Set<T2>;
		some(this:Set<T>, predicate:(value:T)=>unknown):boolean;
	}

	interface String{
		matchCount(this:string, searchString:string):number;
	}

	interface JSON{
		isPrimitive(value:any):value is number|string|boolean|null;
		isJsonable(value:any):boolean;
		toJsonable<T>(value:T):any;
		clone<T>(src:T):T;
	}
}
