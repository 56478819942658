
export function floodFill<T>(
	seed:T,
	iterateConnections:(item:T)=>Iterable<T>,
){
	const island=new Set<T>([seed]);
	let working=new Set<T>([seed]);

	let iteration=1;
	while(working.size>0){
		const next=new Set<T>();
		for(const a of working){
			for(const b of iterateConnections(a)){
				if(!island.has(b)){
					island.add(b);
					next.add(b);
				}
			} 
		}
		working=next;
		iteration+=1;
	}
	return island;
}
