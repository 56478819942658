import { iterate } from '../iterate';
import { math } from '../math';
import { OctTree } from "../oct-tree";

class ItemOctTree<Item> extends OctTree<Item>{
	public constructor(
		leafDim:number,
		private readonly toXyz:(v:Item)=>math.Vec3,
	){
		super(leafDim);//,toXyz,(box,item)=>box.expandByPoint(toXyz(item)));
	}

	public override add(
		item:Item,
		itemPos:math.Vec=this.toXyz(item),
		itemBoxUnion:(box:math.Box3,item:Item)=>void=(box=>box.expandByPoint(itemPos)),
	){
		return super.add(item,itemPos,itemBoxUnion);
	}

	public insideBox(box:math.Box3){
		return this.filterItems(
			node=>node.itemsBox.intersectsBox(box),
			item=>box.containsPoint(this.toXyz(item)),
		);
	}

	public _closest(a:Item, maxDistSq:number){
		const {toXyz}=this;

		const found=super.closest(
			toXyz(a),
			(b,focus)=>{
				if(a===b)
					return Infinity;
				return focus.distToSq(toXyz(b));
			},
			maxDistSq,
		);
		if(found.distSq<=maxDistSq){
			return found.item;
		}
		return null;
	}
}

export function mergeByDistance<Item>(
	items:Iterable<Item>,
	toXyz:(v:Item)=>math.Vec3,
	merge:(a:Item, b:Item)=>void,
	threshold:number,
	onIterate?:(count:number)=>void,
):Item[]{
	const thresholdSq=threshold**2;

	const set=new Map<Item,OctTree.Leaf<Item>|undefined>();
	const box=new math.Box3();
	for(const item of items){
		set.set(item,undefined);
		box.expandByPoint(toXyz(item));		
	}
	if(set.size<2)
		return [...set.keys()];

	const octTreeDimension=box.maxSize()*32/set.size;
	const octTree=new ItemOctTree<Item>(octTreeDimension,toXyz);
	for(const a of set.keys())
		set.set(a,octTree.add(a));

	const closestMap=new Map<Item,Item>();
	for(const a of set.keys()){
		const b=octTree._closest(a,thresholdSq);
		if(b!==null)
			closestMap.set(a,b);
	}

	onIterate?.(set.size);

	let loopCount=0;
	const updateBox=new math.Box3();
	for(let itemCount=set.size+1;set.size<itemCount;){
		itemCount=set.size;

		const itemsToUpdate=new Set<Item>();
		for(const [a,b] of closestMap){
			if(closestMap.get(b)!==a)
				continue;
			updateBox.makeEmpty().expandByPoint(toXyz(a)).expandByPoint(toXyz(b));
			closestMap.delete(b);
			set.get(a)!.deleteItem(a);
			set.get(b)!.deleteItem(b);
			set.delete(b);
			merge(a,b);
			set.set(a,octTree.add(a));
			updateBox.expandByPoint(toXyz(a)).expandByScalar(threshold*1.01);

			for(const item of octTree.insideBox(updateBox))
				itemsToUpdate.add(item);
		}
		for(const a of itemsToUpdate){
			const _b=closestMap.get(a);
			const b=octTree._closest(a,thresholdSq) ?? undefined;
			if(b!==_b){
				if(b!==undefined){
					closestMap.set(a,b);
				}else{
					closestMap.delete(a);
				}
			}
		}

		onIterate?.(set.size);
	}
	return [...set.keys()];
}

function mergeByDistanceBruteForce<Item>(
	items:Iterable<Item>,
	toXyz:(v:Item)=>math.Vec3,
	merge:(a:Item, b:Item)=>void,
	threshold:number,
	onIterate?:(count:number)=>void,
):Item[]{
	const thresholdSq=threshold**2;

	const set=[...items];
	if(set.length<2)
		return set;

	onIterate?.(set.length);

	for(let itemCount=set.length+1;set.length<itemCount;){
		itemCount=set.length;

		const pairs:[number,Item,Item][]=[];
		for(const a of set){
			for(const b of set){
				if(a===b)
					continue;
				const distSq=toXyz(a).distToSq(toXyz(b));
				if(distSq<thresholdSq)
					pairs.push([distSq,a,b]);
			}
		}
		const closest=pairs.lowest(v=>v[0]);
		if(closest){
			const [,a,b]=closest;
			set.splice(set.indexOf(b),1);
			merge(a,b);

		}
		onIterate?.(set.length);
	}
	return set;
}
