<div class="signin-signup-container">
	<div class="left">
		<h1><mat-icon svgIcon="brandLogo"></mat-icon></h1>

		<div class="brand-details">
			<h2>Strayos Platform</h2>
			<h3>Turn your drone data into business outcomes</h3>
			<a mat-button class="primary cta" href="https://www.strayos.com/strayos-platform.html">Know More</a>
		</div>
	</div>

	<div class="right">
		<div class="new-to-platform">
			<span>New to Platform?</span>
			<button mat-button class="secondary cta" (click)="navigateToSignup()">Sign Up</button>
		</div>

		<div class="signin">
			<h2>Sign in to Strayos</h2>
			<small>Enter the valid account details to access platform</small>

			<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onLogin()">
				<mat-form-field appearance="outline">
					<mat-label>Email</mat-label>
					<input matInput formControlName="email" type="email" placeholder="Enter email address">
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Password</mat-label>
					<input matInput formControlName="password" type="password" placeholder="Enter password">
				</mat-form-field>

				<div class="forgot-password">
					<a routerLink="/forgot-password">Forgot Password?</a>
				</div>

				<div class="submit">
					<button mat-button class="primary cta" type="submit" [disabled]="!loginForm.valid && !loggingIn">
						<span *ngIf="!loggingIn">
							Sign in
							<mat-spinner *ngIf="loggingIn"></mat-spinner>
						</span>
					</button>
				</div>

				<div class="no-account">
					Don't have an account? <a routerLink="/sign-up">Sign up for Strayos</a>
				</div>
			</form>

            <!-- <button mat-button class="primary cta" (click)="onLogin()">Sign in</button>

            <div class="others">
                <div class="forgot-password">
                    <a routerLink="/forgot-password">Forgot Password?</a>
                </div>

                <div class="no-account">
                    Don't have an account? <a routerLink="/sign-up">Sign up for Strayos</a>
                </div>
            </div> -->
		</div>
	</div>
</div>